import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Switch, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useParams } from 'react-router';
import Layout from '../../shared/Layout';
import styles from './RegionDetail.module.scss';
import { successToaster } from '../../helpers/notifiers';
import EmploymentTypes from './components/EmploymentTypes';
import { regionsContext } from '../../contexts/RegionsProvider';
import {
  clearRegionDetail,
  fetchRegionDetail,
  getCountryById,
  updateRegionDetail,
} from '../../contexts/actions/regions';
import { Link } from 'react-router-dom';

export default function RegionDetail() {
  const {
    state: { regionDetailType, countries },
  } = useContext(regionsContext);

  const { regionId } = useParams();
  const {
    state: { regionDetailInfo },
    dispatch,
  } = useContext(regionsContext);
  const [regionDataFormState, setRegionDataFormState] = useState(null);
  const [regionCountry, setRegionCountry] = useState(null);

  const loadRegion = useCallback(() => {
    dispatch(fetchRegionDetail(regionId));
  }, [regionId, dispatch]);

  useEffect(() => {
    loadRegion();

    return () => dispatch(clearRegionDetail());
  }, [loadRegion, dispatch]);

  useEffect(() => {
    if (regionDetailInfo) {
      console.log('regionDetailInfo: ', regionDetailInfo);
      console.log(
        'regionDetailInfo.showChildren: ',
        regionDetailInfo.showChildren
      );
      setRegionDataFormState({
        showChildren: regionDetailInfo.showChildren,
        description: regionDetailInfo.description ?? '',
      });
    }
  }, [regionDetailInfo]);

  useEffect(() => {
    if (countries) {
      setRegionCountry(dispatch(getCountryById(regionDetailInfo?.parentId)));
    }
  }, [countries, regionDetailInfo, dispatch]);

  const { name, employmentTypes } = regionDetailInfo ?? {};

  const handleRegionDataSubmit = async () => {
    const { description, showChildren } = regionDataFormState;
    const isDescriptionEmpty = !description;
    let allowSubmition = true;
    if (isDescriptionEmpty) {
      // eslint-disable-next-line no-restricted-globals
      allowSubmition = confirm('Description is empty, are u sure?');
    }

    if (allowSubmition) {
      const data = { description, showChildren };
      try {
        await dispatch(updateRegionDetail(regionId, data));
        successToaster('Region data was successfully updated!');
      } catch (e) {
        console.log('error: ', e);
      }
    } else {
      setRegionDataFormState({
        description: regionDetailInfo.description,
        showChildren: regionDetailInfo.showChildren,
      });
    }
  };

  return (
    <Layout>
      <Box
        className={styles.section}
        display={'flex'}
        flexDirection={'column'}
        position={'relative'}
      >
        <Typography variant={'h1'} marginBottom={3}>
          {name}
        </Typography>

        {regionCountry && (
          <Typography variant={'h2'} marginBottom={4}>
            Country:{' '}
            <Link className={styles.link} to={`/regions/${regionCountry.id}`}>
              {regionCountry.name}
            </Link>
          </Typography>
        )}

        {regionDataFormState && (
          <Box>
            <Typography variant={'h3'} marginY={2}>
              Description
            </Typography>
            <CKEditor
              editor={ClassicEditor}
              data={regionDataFormState.description}
              onChange={(_, editor) => {
                const data = editor.getData();
                setRegionDataFormState({
                  ...regionDataFormState,
                  description: data,
                });
              }}
            />

            <Box marginY={5}>
              <Typography variant={'h3'} marginBottom={2}>
                Show Regions on map
              </Typography>

              <Switch
                checked={regionDataFormState.showChildren}
                onChange={() =>
                  setRegionDataFormState({
                    ...regionDataFormState,
                    showChildren: !regionDataFormState.showChildren,
                  })
                }
              />
            </Box>
          </Box>
        )}

        <Button
          className={styles.btn}
          variant={'contained'}
          color={'success'}
          onClick={handleRegionDataSubmit}
        >
          Save
        </Button>

        {regionDetailType === 'Country' && (
          <EmploymentTypes
            loadRegion={loadRegion}
            employmentTypes={employmentTypes ?? []}
          />
        )}
      </Box>
    </Layout>
  );
}
