import { toast } from 'react-toastify';

export const successToaster = (msg) =>
  toast(<p style={{ fontSize: 16 }}>{msg}</p>, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    type: 'success',
  });

export const warningToaster = (msg) =>
  toast(<p style={{ fontSize: 16 }}>{msg}</p>, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    type: 'warning',
  });

export const errorToaster = (msg) =>
  toast(<p style={{ fontSize: 16 }}>{msg}</p>, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    type: 'error',
  });
