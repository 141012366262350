import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Layout from '../../shared/Layout';
import EmploymentTypeReadMode from './ReadModeComponents/EmploymentTypeReadMode';
import EmploymentTypeEditMode from './EditModeComponents/EmploymentTypeEditMode';

export default function EmploymentTypeDetail() {
  const queryParams = new URLSearchParams();
  const location = useLocation();
  const history = useHistory();
  const mounted = useRef();

  const [isEditMode, setIsEditMode] = useState(
    location.search === '?edit=true'
  );

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (location.search.startsWith('?edit=true')) {
        setIsEditMode(true);
      } else {
        setIsEditMode(false);
      }
    }
  }, [location.search]);

  const handleSwitchToEditMode = () => {
    queryParams.append('edit', true);
    history.push({ search: queryParams.toString() });
  };

  const handleSwitchToReadOnlyMode = () => {
    queryParams.delete('edit');
    history.push({ search: queryParams.toString() });
  };

  return (
    <Layout>
      {isEditMode ? (
        <EmploymentTypeEditMode switchMode={handleSwitchToReadOnlyMode} />
      ) : (
        <EmploymentTypeReadMode switchMode={handleSwitchToEditMode} />
      )}
    </Layout>
  );
}
