import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { DataGrid } from '@mui/x-data-grid';
import {
  deleteTax,
  updateTax,
  createTaxBracket,
  updateTaxBracket,
} from '../api';
import { errorToaster, successToaster } from '../../../helpers/notifiers';
import AddTaxDialog from './AddTaxDialog';
import Loader from '../../../shared/Loader';
import TaxBracketTableEditMode from './TaxBracketTableEditMode';
import EditTaxDescription from './EditTaxDescription';

function TaxTableEditMode({
  taxes,
  loadData,
  employmentTypeId,
  regionId,
  type,
}) {
  const [currentlyUpdatingRows, setCurrentlyUpdatingRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [areRowsEditable, setAreRowsEditable] = useState(true);
  const [editingRowsId, setEditingRowsId] = useState([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditTaxDescriptionOpen, setIsEditTaxDescriptionOpen] =
    useState(false);
  const [editingTaxDescriptionObj, setEditingTaxDescriptionObj] =
    useState(null);

  const [taxBracketsTableData, setTaxBracketsTableData] = useState({
    taxBrackets: [],
    taxId: null,
  });
  const [isTaxBracketsPageOpen, setIsTaxBracketsPageOpen] = useState(false);

  const handleTaxBracketsPageOpen = (tax) => {
    setIsTaxBracketsPageOpen(true);
    setTaxBracketsTableData({
      taxBrackets: tax.brackets,
      taxId: tax.id,
    });
  };

  const handleTaxBracketsPageClose = () => {
    loadData();
    setIsTaxBracketsPageOpen(false);
    setTaxBracketsTableData({
      taxBrackets: [],
      taxId: null,
    });
  };

  const handleTaxDescriptionEditClose = useCallback(
    () => setIsEditTaxDescriptionOpen(false),
    []
  );
  const handleAddDialogOpen = useCallback(() => setIsAddDialogOpen(true), []);
  const handleAddDialogClose = useCallback(() => setIsAddDialogOpen(false), []);

  useEffect(() => {
    const wasTaxEdited = (taxId) => {
      return editingRowsId.includes(taxId);
    };
    setLoading(true);
    setRows(
      taxes
        .map((tax) => {
          if (wasTaxEdited(tax.id)) {
            return rows.find((row) => row.id === tax.id);
          } else {
            return tax.brackets.length
              ? {
                  ...tax,
                  isFlat: tax.brackets[0].isFlat,
                  value: tax.brackets[0].value,
                }
              : { ...tax, isFlat: false, value: 0 };
          }
        })
        .sort((a, b) => a.order - b.order)
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxes]);

  const isRowValid = (row) => {
    if (row.name) return true;
    return false;
  };

  const transformRowBeforeSend = (row, taxId) => {
    const taxData = { ...row };
    let from = 0;
    let to = null;
    if (taxData.brackets.length) {
      from = taxData.brackets[0].from;
      to = taxData.brackets[0].to;
    }
    delete taxData.brackets;
    delete taxData.employmentTypeId;
    delete taxData.id;
    const taxBracketData = {
      taxId,
      from,
      to,
      value: taxData.value,
      isFlat: taxData.isFlat,
    };
    delete taxData.isFlat;
    delete taxData.value;
    return { taxData, taxBracketData };
  };

  const handleTaxUpdate = async (updatingRow) => {
    if (isRowValid(updatingRow)) {
      const taxId = updatingRow.id;
      setCurrentlyUpdatingRows([...currentlyUpdatingRows, taxId]);
      try {
        const bracketsQuantity = updatingRow.brackets.length;
        const { taxData, taxBracketData } = transformRowBeforeSend(
          updatingRow,
          taxId
        );

        switch (bracketsQuantity) {
          case 0: {
            await updateTax(taxId, taxData);
            await createTaxBracket(taxBracketData);
            break;
          }
          default: {
            const taxBracketId = updatingRow.brackets[0].id;
            await updateTax(taxId, taxData);
            await updateTaxBracket(taxBracketId, taxBracketData);
            break;
          }
        }
        successToaster('Tax was successfully updated!');
        setEditingRowsId(editingRowsId.filter((id) => id !== taxId));
        setCurrentlyUpdatingRows(
          currentlyUpdatingRows.filter((id) => id !== taxId)
        );
        loadData();
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      errorToaster(
        `Name is required field, check row with id "${updatingRow.id}"`
      );
    }
  };

  const handleDeleteTax = (id) => {
    // eslint-disable-next-line no-restricted-globals
    const deleteConfirm = confirm(`Are u sure, delete tax with id "${id}"?`);
    if (deleteConfirm) {
      deleteTax(id)
        .then(() => {
          successToaster('Tax was successfully deleted!');
          loadData();
        })
        .catch((error) => {
          console.log('error: ', error);
        });
    }
  };

  const handleFieldRowCellChange = (e) => {
    const { field, id, value } = e;
    console.log('e: ', e);

    setAreRowsEditable(false);
    const editingCellIndex = rows.findIndex((row) => row.id === id);

    if (rows[editingCellIndex][field] !== value) {
      const rowsCopy = [...rows];
      rowsCopy[editingCellIndex] = {
        ...rowsCopy[editingCellIndex],
        [field]: value,
      };
      setRows(rowsCopy);
      setEditingRowsId([...editingRowsId, id]);
    }
    setAreRowsEditable(true);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'name',
      headerName: 'Name*',
      width: 180,
      required: true,
      editable: areRowsEditable,
    },
    {
      field: 'description',
      headerName: 'Description',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setEditingTaxDescriptionObj({
              id: params.id,
              description: params.value,
            });
            setIsEditTaxDescriptionOpen(true);
          }}
        >
          <ManageSearchIcon color={'info'} />
        </IconButton>
      ),
    },
    {
      field: 'isPersonal',
      headerName: 'Level*',
      width: 180,
      editable: areRowsEditable,
      renderCell: (params) => (
        <FormControl fullWidth>
          <Select
            onChange={() =>
              handleFieldRowCellChange({ ...params, value: !params.value })
            }
            value={params.value}
            label="Level"
          >
            <MenuItem value={true}>Personal</MenuItem>
            <MenuItem value={false}>Company</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      field: 'topLimit',
      headerName: 'Top limit',
      width: 90,
      align: 'center',
      headerAlign: 'center',
      editable: areRowsEditable,
      type: 'number',
    },
    {
      field: 'deduction',
      headerName: 'Deduction',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      editable: areRowsEditable,
      type: 'number',
    },
    {
      field: 'order',
      headerName: 'Order',
      width: 70,
      align: 'center',
      headerAlign: 'center',
      required: true,
      editable: areRowsEditable,
      type: 'number',
    },
    {
      field: 'hasBrackets',
      headerName: 'Has Bracket(s)',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) =>
        params.row.brackets.length > 1 ? (
          <DoneIcon color={'success'} />
        ) : (
          <CloseIcon color={'error'} />
        ),
    },
    {
      field: 'isFlat',
      headerName: 'Type',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      visible: false,
      renderCell: (params) => (
        <FormControl fullWidth>
          <Select
            onChange={() =>
              handleFieldRowCellChange({ ...params, value: !params.value })
            }
            value={params.value}
            label="Type"
          >
            <MenuItem value={true}>Flat</MenuItem>
            <MenuItem value={false}>Percent</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 70,
      align: 'center',
      headerAlign: 'center',
      required: true,
      editable: areRowsEditable,
      type: 'number',
    },
    {
      field: 'actionBtns',
      headerName: 'Action',
      width: 250,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Box display={'flex'} justifyContent={'space-between'}>
          <Button
            color={'info'}
            variant={'contained'}
            disabled={
              !editingRowsId.includes(params.id) ||
              currentlyUpdatingRows.includes(params.id)
            }
            onClick={() => handleTaxUpdate(params.row)}
          >
            Save
          </Button>
          <IconButton onClick={() => handleDeleteTax(params.id)}>
            <DeleteIcon />
          </IconButton>

          <Button
            disabled={!params.row.brackets.length}
            color={'info'}
            variant={'outlined'}
            style={{ fontSize: 10 }}
            onClick={() => handleTaxBracketsPageOpen(params.row)}
          >
            Add/Edit <br /> tax bracket(s)
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box width={'100%'} height={'70vh'} marginBottom={20}>
      {isLoading && <Loader />}
      <Typography variant={'h2'} marginBottom={2}>
        {type} Taxes Table
      </Typography>

      <IconButton onClick={handleAddDialogOpen}>
        <AddCircleIcon />
      </IconButton>

      <Box marginBottom={2} />

      <DataGrid
        columns={columns}
        rows={rows}
        isRowSelectable={() => null}
        pageSize={100}
        onCellEditCommit={handleFieldRowCellChange}
      />

      <AddTaxDialog
        isOpen={isAddDialogOpen}
        handleClose={handleAddDialogClose}
        loadData={loadData}
        employmentTypeId={employmentTypeId}
        regionId={regionId}
      />

      <EditTaxDescription
        {...editingTaxDescriptionObj}
        loadData={loadData}
        isOpen={isEditTaxDescriptionOpen}
        handleClose={handleTaxDescriptionEditClose}
      />

      {isTaxBracketsPageOpen ? (
        <TaxBracketTableEditMode
          taxBrackets={taxBracketsTableData.taxBrackets}
          taxId={taxBracketsTableData.taxId}
          isOpen={isTaxBracketsPageOpen}
          handleClose={handleTaxBracketsPageClose}
        />
      ) : null}
    </Box>
  );
}

export default React.memo(TaxTableEditMode);
