import React, { memo, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import {
  AppBar,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { createTax } from '../api';
import { successToaster } from '../../../helpers/notifiers';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddTaxDialog({
  isOpen,
  handleClose,
  employmentTypeId,
  regionId,
  loadData,
}) {
  const [formState, setFormState] = useState({
    name: '',
    description: '',
    isPersonal: true,
    topLimit: '',
    deduction: '',
    order: 0,
  });

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleRichTextChange = (description) =>
    setFormState({
      ...formState,
      description,
    });

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      ...formState,
      regionId: +regionId,
      employmentTypeId: +employmentTypeId,
      deduction: formState.deduction === '' ? 0 : +formState.deduction,
      topLimit: formState.topLimit === '' ? null : +formState.topLimit,
      order: +formState.order,
    };

    createTax(data)
      .then(() => {
        successToaster('Tax was successfully added!');
        loadData();
        setFormState({
          name: '',
          isPersonal: true,
          description: '',
          deduction: '',
          topLimit: '',
          order: 0,
        });
        handleClose();
      })
      .catch((error) => console.log('error: ', error));
  };

  const handleCloseAndClearForm = () => {
    setFormState({
      name: '',
      description: '',
      isPersonal: true,
      topLimit: '',
      deduction: '',
      order: 0,
    });
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseAndClearForm}
      TransitionComponent={Transition}
      fullScreen
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box marginTop={10} maxWidth={900} width={'100%'} marginX={'auto'}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Create New Tax</DialogTitle>
          <DialogContent>
            <TextField
              required
              autoFocus
              fullWidth
              margin="dense"
              name="name"
              label="Name"
              type="text"
              variant="outlined"
              value={formState.name}
              onChange={handleInputChange}
            />

            <Box marginBottom={3} />

            <FormControl fullWidth>
              <InputLabel>Level *</InputLabel>
              <Select
                name={'isPersonal'}
                value={formState.isPersonal}
                onChange={handleInputChange}
                label={'Level*'}
              >
                <MenuItem value={true}>Personal</MenuItem>
                <MenuItem value={false}>Company</MenuItem>
              </Select>
            </FormControl>

            <Box marginBottom={3} />

            <TextField
              autoFocus
              fullWidth
              margin="dense"
              name="topLimit"
              label="Top Limit"
              type="number"
              inputProps={{
                min: 1,
              }}
              variant="outlined"
              value={formState.topLimit}
              onChange={handleInputChange}
            />

            <Box marginBottom={3} />

            <TextField
              autoFocus
              fullWidth
              margin="dense"
              name="deduction"
              label="Deduction"
              type="number"
              inputProps={{
                min: 1,
              }}
              variant="outlined"
              value={formState.deduction}
              onChange={handleInputChange}
            />

            <Box marginBottom={3} />

            <TextField
              required
              autoFocus
              fullWidth
              margin="dense"
              name="order"
              label="Order"
              type="number"
              inputProps={{
                min: 0,
              }}
              variant="outlined"
              value={formState.order}
              onChange={handleInputChange}
            />

            <Box marginBottom={3} />

            <Typography variant={'body1'} marginBottom={1}>
              Description
            </Typography>

            <CKEditor
              editor={ClassicEditor}
              data={formState.description}
              onChange={(_, editor) => {
                const data = editor.getData();
                handleRichTextChange(data);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAndClearForm}>Cancel</Button>
            <Button type="submit" color="success" variant="contained">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
}

export default memo(AddTaxDialog);
