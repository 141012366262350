import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Regions from '../views/Regions';
import RegionComments from '../views/RegionComments';
import RegionDetail from '../views/RegionDetail';
import EmploymentTypeDetail from '../views/EmploymentTypeDetail';

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/" render={() => <Redirect to="/regions" />} exact />
        <Route path="/regions" component={Regions} exact />
        <Route path="/regions/:regionId" component={RegionDetail} exact />
        <Route
          path="/regions/:regionId/comments"
          component={RegionComments}
          exact
        />
        <Route
          path="/employmentType/:employmentTypeId"
          component={EmploymentTypeDetail}
          exact
        />
      </Switch>
    </Router>
  );
}
