import React, { useEffect, useRef, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import {
  Badge,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CachedIcon from '@mui/icons-material/Cached';
import styles from './RegionsTable.module.scss';
import { Link } from 'react-router-dom';
import TableQuickSearchToolbar from '../TableQuickSearchToolbar';

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

// ! tableType must be "countries" or "regions"
export default function RegionsTable({
  entites,
  tableType,
  switchToRegions,
  regionsCountryName,
}) {
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isFilterOn, setIsFilterOn] = useState(false);

  const mounted = useRef(false);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = entites.filter((row) => {
      return searchRegex.test(row.name.toString());
    });
    setRows(filteredRows);
  };

  useEffect(() => {
    setRows(entites);
  }, [entites]);

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      if (isFilterOn) {
        const filteredCountries = entites.filter(
          (region) => region.newComments
        );
        setRows(filteredCountries);
      } else {
        setRows(entites);
      }
    }
  }, [entites, isFilterOn]);

  const baseColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      renderCell: (params) => (
        <Link to={`/regions/${params.id}`} className={styles.link}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'code',
      headerName: 'Code',
      width: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'newComments',
      headerName: 'New comments',
      width: 150,
      align: 'center',
      renderCell: (params) => (
        <Badge color={'error'} badgeContent={params.value}>
          <CommentIcon />
        </Badge>
      ),
    },
    {
      field: 'approvedComments',
      headerName: 'Approved comments',
      width: 180,
      align: 'center',
      renderCell: (params) => (
        <Badge color={'success'} badgeContent={params.value}>
          <FactCheckIcon />
        </Badge>
      ),
    },
    {
      field: 'inProgressComments',
      headerName: 'In progress comments',
      align: 'center',
      width: 180,
      renderCell: (params) => (
        <Badge color={'info'} badgeContent={params.value}>
          <CachedIcon />
        </Badge>
      ),
    },
  ];

  const columns =
    tableType === 'regions'
      ? [...baseColumns]
      : [
          ...baseColumns,
          {
            field: 'switchToRegions',
            headerName: 'Switch to regions',
            align: 'center',
            headerAlign: 'center',
            width: 200,
            renderCell: (params) => (
              <Typography
                variant={'body2'}
                className={styles.link}
                onClick={() => switchToRegions(params.id, params.row.name)}
              >
                Regions
              </Typography>
            ),
          },
        ];

  return (
    <Box
      width={'100%'}
      height={'70vh'}
      marginBottom={20}
      className={styles.section}
    >
      <Typography variant={'h2'} marginBottom={2}>
        {regionsCountryName ?? null}{' '}
        {tableType.replace(/./, (c) => c.toUpperCase())}
      </Typography>

      <Box display={'flex'} marginBottom={1} marginTop={3}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value={isFilterOn}
                onChange={() => setIsFilterOn(!isFilterOn)}
              />
            }
            label={`Show ${tableType} with new comments`}
          />
        </FormGroup>
      </Box>

      <DataGrid
        components={{ Toolbar: TableQuickSearchToolbar }}
        rows={rows}
        columns={columns}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(''),
          },
        }}
        isRowSelectable={() => null}
        pageSize={100}
      />
    </Box>
  );
}
