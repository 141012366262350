import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import CommentIcon from '@mui/icons-material/Comment';
import CheckIcon from '@mui/icons-material/Check';
import CachedIcon from '@mui/icons-material/Cached';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import BlockIcon from '@mui/icons-material/Block';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import EditIcon from '@mui/icons-material/Edit';

import CommentDetail from './components/CommentDetail';
import EditComment from './components/EditComment';

import { fetchCountryComments } from './api';
import Layout from '../../shared/Layout';
import Loader from '../../shared/Loader';
import { useParams } from 'react-router';

import styles from './RegionComments.module.scss';

const renderStatusBadge = (status) => {
  switch (status) {
    case 1:
      return (
        <IconButton
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <CommentIcon fontSize={'small'} />
          <Typography
            marginLeft={1}
            fontSize={11}
            paddingY={0.2}
            paddingX={0.5}
            borderRadius={15}
            position={'absolute'}
            top={-5}
            right={-10}
            variant={'body1'}
            style={{ background: 'red', color: '#fff' }}
          >
            New
          </Typography>
        </IconButton>
      );
    case 2:
      return (
        <IconButton
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ThumbUpIcon fontSize={'small'} />
          <Typography marginLeft={1} fontSize={12} variant={'body1'}>
            Approved
          </Typography>
        </IconButton>
      );
    case 3:
      return (
        <IconButton
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CachedIcon fontSize={'small'} />
          <Typography marginLeft={1} fontSize={12} variant={'body1'}>
            In progress
          </Typography>
        </IconButton>
      );
    case 4:
      return (
        <IconButton
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CheckIcon fontSize={'small'} />
          <Typography marginLeft={1} fontSize={12} variant={'body1'}>
            Resolved
          </Typography>
        </IconButton>
      );
    case 5:
      return (
        <IconButton
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <BlockIcon fontSize={'small'} />
          <Typography marginLeft={1} fontSize={12} variant={'body1'}>
            Declined
          </Typography>
        </IconButton>
      );
    default:
      break;
  }
};

export default function RegionComments() {
  const [comments, setComments] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [rows, setRows] = useState([]);
  const [filterStatus, setFilterStatus] = useState({
    new: false,
    approved: false,
    inProgress: false,
    resolved: false,
    declined: false,
  });
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);

  const handleDetailDialogOpen = (commentData) => {
    setSelectedComment(commentData);
    setIsDetailDialogOpen(true);
  };
  const handleDetailDialogClose = () => setIsDetailDialogOpen(false);

  const handleEditDialogOpen = (commentData) => {
    setSelectedComment(commentData);
    setIsEditDialogOpen(true);
  };
  const handleEditDialogClose = () => setIsEditDialogOpen(false);

  const handleFilterStatusChange = (statusName) => {
    setFilterStatus({
      ...filterStatus,
      [statusName]: !filterStatus[statusName],
    });
  };

  const mounted = useRef(false);
  const { regionId } = useParams();

  const loadComments = useCallback(() => {
    setIsloading(true);
    fetchCountryComments(regionId)
      .then((fetchedComments) => {
        setComments(fetchedComments);
        setIsloading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        setIsloading(false);
      });
  }, [regionId]);

  useEffect(() => {
    loadComments();
  }, [loadComments]);

  useEffect(() => {
    const commentsCopy = [...comments];
    setRows(commentsCopy.sort((a, b) => a.status - b.status));
  }, [comments]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      const filteringStatusNumbers = [
        filterStatus.new ? 1 : null,
        filterStatus.approved ? 2 : null,
        filterStatus.inProgress ? 3 : null,
        filterStatus.resolved ? 4 : null,
        filterStatus.declined ? 5 : null,
      ];

      const filteredComments = comments.filter((row) =>
        filteringStatusNumbers.includes(+row.status)
      );

      if (filteredComments.length) {
        setRows(filteredComments);
      } else {
        setRows(comments);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 60,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 180,
      editable: true,
    },
    {
      field: 'text',
      headerName: 'Text',
      width: 350,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => renderStatusBadge(params.value),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box display={'flex'} justifyContent={'space-between'}>
          <IconButton onClick={() => handleDetailDialogOpen(params.row)}>
            <ManageSearchOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => handleEditDialogOpen(params.row)}>
            <EditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Layout>
      {isLoading && <Loader />}
      <Box className={styles.section}>
        <Box height={'500px'} marginBottom={20}>
          <Typography variant={'h1'} marginBottom={2}>
            Comments
          </Typography>

          <FormGroup>
            <Box display={'flex'} marginBottom={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => handleFilterStatusChange('new')}
                    value={filterStatus.new}
                  />
                }
                label={'New'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => handleFilterStatusChange('approved')}
                    value={filterStatus.approved}
                  />
                }
                label={'Approved'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => handleFilterStatusChange('inProgress')}
                    value={filterStatus.inProgress}
                  />
                }
                label={'In progress'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => handleFilterStatusChange('resolved')}
                    value={filterStatus.resolved}
                  />
                }
                label={'Resolved'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => handleFilterStatusChange('declined')}
                    value={filterStatus.declined}
                  />
                }
                label={'Declined'}
              />
            </Box>
          </FormGroup>

          <DataGrid
            rows={rows}
            columns={columns}
            isRowSelectable={() => null}
            pageSize={100}
          />

          {selectedComment && (
            <React.Fragment>
              <CommentDetail
                comment={selectedComment}
                isOpen={isDetailDialogOpen}
                handleClose={handleDetailDialogClose}
              />

              <EditComment
                comment={selectedComment}
                isOpen={isEditDialogOpen}
                handleClose={handleEditDialogClose}
                loadComments={loadComments}
              />
            </React.Fragment>
          )}
        </Box>
      </Box>
    </Layout>
  );
}
