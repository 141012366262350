import axiosClient from '../../services/configuredAxios';

export const fetchCountryComments = async (regionId) => {
  try {
    const { data } = await axiosClient.get(`/comment/?regionId=${regionId}`);
    return data.result;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateCommentStatus = async (id, data) => {
  try {
    await axiosClient.put(`/comment/${id}`, data);
  } catch (error) {
    throw new Error(error.message);
  }
};
