import React, { memo, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Slide from '@mui/material/Slide';
import styles from '../EmploymentTypeDetail.module.scss';
import EditTaxBracketDialog from './EditTaxBracketDialog';
import AddTaxBracketDialog from './AddTaxBracketDialog';
import { deleteTaxBracket, updateTaxBracket } from '../api';
import { successToaster } from '../../../helpers/notifiers';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TaxBracketTableEditMode({ taxBrackets, isOpen, handleClose, taxId }) {
  const [rows, setRows] = useState([]);
  const [taxBracketToEditData, setTaxBracketToEditData] = useState(null);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const handleAddDialogOpen = () => {
    setIsAddDialogOpen(true);
  };
  const handleAddDialogClose = () => setIsAddDialogOpen(false);

  const handleEditDialogOpen = (taxBracket) => {
    const taxBracketIndex = rows.findIndex((row) => row.id === taxBracket.id);
    setTaxBracketToEditData({
      ...taxBracket,
      index: taxBracketIndex,
      taxBracketsAmount: rows.length,
    });
    setIsEditDialogOpen(true);
  };
  const handleEditDialogClose = () => setIsEditDialogOpen(false);

  const handleDelete = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmDelete = confirm(
      `Are u sure to delete taxBracket with id "${id}"`
    );
    if (confirmDelete) {
      try {
        await deleteTaxBracket(id);
        const deletingTaxBracketIndex = rows.findIndex((row) => row.id === id);
        if (
          deletingTaxBracketIndex !== 0 &&
          deletingTaxBracketIndex !== rows.length - 1
        ) {
          const nextToDeletingTaxBracket = rows[deletingTaxBracketIndex + 1];
          const deleteingTaxBracket = rows[deletingTaxBracketIndex];
          await updateTaxBracket(nextToDeletingTaxBracket.id, {
            ...nextToDeletingTaxBracket,
            from: +deleteingTaxBracket.from,
          });
          setRows(
            rows
              .map((row) =>
                row.id === nextToDeletingTaxBracket.id
                  ? { ...row, from: +deleteingTaxBracket.from }
                  : row
              )
              .filter((row) => row.id !== id)
          );
        } else {
          setRows(rows.filter((row) => row.id !== id));
        }
        successToaster('Tax bracket was successfully deleted!');
      } catch (error) {
        console.log('error: ', error);
      }
    }
  };

  useEffect(() => {
    setRows(taxBrackets);
  }, [taxBrackets]);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'from',
      headerName: 'From',
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'to',
      headerName: 'To',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) =>
        params.value ? (
          <Typography variant={'body2'}>{params.value}</Typography>
        ) : (
          <Typography variant={'body2'}>and up</Typography>
        ),
    },
    {
      field: 'isFlat',
      headerName: 'Type',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      visible: false,
      renderCell: (params) =>
        params.value ? (
          <Typography variant={'body2'}>Flat</Typography>
        ) : (
          <Typography variant={'body2'}>Percent</Typography>
        ),
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 70,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'actionBtns',
      headerName: 'Action',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Box display={'flex'} justifyContent={'space-between'}>
          <IconButton onClick={() => handleEditDialogOpen(params.row)}>
            <EditIcon />
          </IconButton>
          {rows.findIndex((row) => row.id === params.id) === 0 ? null : (
            <IconButton onClick={() => handleDelete(params.id)}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box height={'70vh'} className={styles.section}>
        <Typography variant={'h1'} marginY={2}>
          Tax brackets
        </Typography>

        <IconButton onClick={handleAddDialogOpen}>
          <AddCircleIcon />
        </IconButton>

        <DataGrid
          columns={columns}
          rows={rows}
          isRowSelectable={() => null}
          pageSize={100}
        />

        {isEditDialogOpen ? (
          <EditTaxBracketDialog
            data={taxBracketToEditData}
            isOpen={isEditDialogOpen}
            handleClose={handleEditDialogClose}
            rows={rows}
            setRows={setRows}
          />
        ) : null}

        {isAddDialogOpen ? (
          <AddTaxBracketDialog
            isOpen={isAddDialogOpen}
            handleClose={handleAddDialogClose}
            rows={rows}
            setRows={setRows}
            from={rows[rows.length - 1].to}
            taxId={taxId}
          />
        ) : null}
      </Box>
    </Dialog>
  );
}

export default memo(TaxBracketTableEditMode);
