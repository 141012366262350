export const normalizeTaxes = (taxes) => {
  return taxes.map((tax) => {
    if (tax.brackets.length) {
      const sortedTaxBrackets = tax.brackets.sort((a, b) => a.from - b.from);
      return {
        ...tax,
        brackets: sortedTaxBrackets,
        isFlat: sortedTaxBrackets[0].isFlat,
        value: sortedTaxBrackets[0].value,
      };
    } else {
      return { ...tax, isFlat: false, value: 0 };
    }
  });
};
