import axiosClient from '../../services/configuredAxios';
import {
  START_REQUEST,
  COUNTRIES_SUCCESS,
  REQUEST_ERROR,
  REGION_SUCCESS,
  CLEAR_REGION_INFO,
  REGIONS_SUCCESS,
  REGION_DETAIL_TYPE_TO_COUNTRY,
  REGION_DETAIL_TYPE_TO_REGION,
} from '../actionTypes/regions';

export const fetchCountries = () => async (dispatch) => {
  dispatch({ type: START_REQUEST });
  try {
    const {
      data: { result },
    } = await axiosClient.get(`/region`);

    const { countries, regions } = result.reduce(
      (accum, region) => {
        if (region.parentId) {
          accum.regions.push(region);
        } else {
          accum.countries.push(region);
        }
        return accum;
      },
      {
        countries: [],
        regions: [],
      }
    );
    dispatch({
      type: COUNTRIES_SUCCESS,
      payload: countries,
    });
    dispatch({
      type: REGIONS_SUCCESS,
      payload: regions,
    });
  } catch (error) {
    dispatch({
      type: REQUEST_ERROR,
    });
    throw new Error(error);
  }
};

export const fetchRegionDetail = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_REQUEST });
    const {
      data: { result },
    } = await axiosClient.get(`/region/${id}`);
    dispatch({
      type: REGION_SUCCESS,
      payload: result,
    });
    if (result.parentId) {
      dispatch(swithRegionDetailTypeToRegion());
    } else {
      dispatch(swithRegionDetailTypeToCountry());
    }
  } catch (error) {
    dispatch({
      type: REQUEST_ERROR,
    });
    throw new Error(error);
  }
};

export const updateRegionDetail = (id, data) => async (dispatch) => {
  dispatch({ type: START_REQUEST });
  try {
    await axiosClient.put(`/region/${id}`, data);
    dispatch(fetchRegionDetail(id));
  } catch (error) {
    dispatch({
      type: REQUEST_ERROR,
    });
    throw new Error(error);
  }
};

export const clearRegionDetail = () => (dispatch) =>
  dispatch({ type: CLEAR_REGION_INFO });

export const getCountryRegions = (countryId) => (_, getState) => {
  const { regions, countries } = getState();
  const countryRegions = regions.filter(
    (region) => region.parentId === countryId
  );
  const country = countries.find((country) => country.id === countryId);
  return { countryRegions, country };
};

export const getCountryById = (countryId) => (_, getState) => {
  const { countries } = getState();
  const country = countries.find((country) => country.id === countryId);
  return country;
};

export const swithRegionDetailTypeToCountry = () => (dispatch) =>
  dispatch({ type: REGION_DETAIL_TYPE_TO_COUNTRY });

export const swithRegionDetailTypeToRegion = () => (dispatch) =>
  dispatch({ type: REGION_DETAIL_TYPE_TO_REGION });
