import { Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { getCountryRegions } from '../../contexts/actions/regions';
import { regionsContext } from '../../contexts/RegionsProvider';
import useQueryParams from '../../hooks/useQueryParams';
import Layout from '../../shared/Layout';
import RegionsTable from '../../shared/RegionsTable';
import styles from '../../shared/RegionsTable/RegionsTable.module.scss';

export default function Regions() {
  const {
    state: { countries },
    dispatch,
  } = useContext(regionsContext);

  const [tableType, setTableType] = useState('countries');
  const [entites, setEntites] = useState([]);
  const [regionsCountryName, setRegionsCountryName] = useState('');
  const { parentId } = useQueryParams();

  const history = useHistory();
  const location = useLocation();

  const switchToRegions = (countryId) => {
    history.push({
      pathname: location.pathname,
      search: `parentId=${countryId}`,
    });
  };

  const swithToCountries = () => {
    history.push({
      pathname: location.pathname,
      search: '',
    });
  };

  useEffect(() => {
    if (parentId) {
      setTableType('regions');
      const { countryRegions, country } = dispatch(
        getCountryRegions(+parentId)
      );
      setEntites(countryRegions);
      setRegionsCountryName(country?.name);
    } else {
      setTableType('countries');
      setEntites(countries);
      setRegionsCountryName('');
    }
  }, [parentId, countries, dispatch]);

  return (
    <Layout>
      {tableType === 'regions' ? (
        <Button
          variant={'outlined'}
          onClick={swithToCountries}
          className={styles.backToCountriesBtn}
        >
          Back to countries
        </Button>
      ) : null}

      <RegionsTable
        entites={entites}
        tableType={tableType}
        switchToRegions={switchToRegions}
        swithToCountries={swithToCountries}
        regionsCountryName={regionsCountryName}
      />
    </Layout>
  );
}
