import axiosClient from '../../services/configuredAxios';

export const fetchEmploymentType = async (id) => {
  try {
    const { data } = await axiosClient.get(`EmploymentType/${id}`);
    return data.result;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateEmploymentType = async (id, data) => {
  try {
    await axiosClient.put(`/EmploymentType/${id}`, data);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateTax = async (id, data) => {
  try {
    await axiosClient.put(`/Tax/${id}`, data);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteTax = async (id) => {
  try {
    await axiosClient.delete(`/Tax/${id}`);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const createTax = async (data) => {
  try {
    await axiosClient.post(`/Tax`, data);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const createTaxBracket = async (data) => {
  try {
    const response = await axiosClient.post(`/TaxBracket`, data);
    return response.data.result;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateTaxBracket = async (id, data) => {
  try {
    await axiosClient.put(`/TaxBracket/${id}`, data);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteTaxBracket = async (id) => {
  try {
    await axiosClient.delete(`/TaxBracket/${id}`);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getOrCreateEmploymentTypeDraft = async (parentId) => {
  try {
    const { data } = await axiosClient.post(
      `/EmploymentType/${parentId}/draft/copy`
    );
    return data.result;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const publishEmploymentTypeDraft = async (parentId) => {
  try {
    await axiosClient.post(`/EmploymentType/${parentId}/draft/publish`);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const revertEmploymentTypeDraft = async (parentId) => {
  try {
    await axiosClient.post(`/EmploymentType/${parentId}/draft/revert`);
  } catch (error) {
    throw new Error(error.message);
  }
};
