import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { updateTaxBracket } from '../api';
import { errorToaster, successToaster } from '../../../helpers/notifiers';

export default function EditTaxBracketDialog({
  isOpen,
  handleClose,
  data,
  setRows,
  rows,
}) {
  const {
    from,
    to,
    value,
    isFlat,
    index,
    taxBracketsAmount,
    id: editingTaxBracetId,
  } = data;
  const [formState, setFormState] = useState({
    from,
    to,
    value,
    isFlat,
  });

  const validateToValue = (value) => {
    if (value < 0) {
      return {
        isValid: false,
        message: `Field "To" is invalid: (value can't be under 0)`,
      };
    }

    if (taxBracketsAmount > 1 && value === 0 && index === 0) {
      return {
        isValid: false,
        message: `Field "To" is invalid: (value can't be 0)`,
      };
    }

    const prevRow = rows[index - 1];
    const nextRow = rows[index + 1];
    if (prevRow && nextRow) {
      if (nextRow.to !== '' && nextRow.to !== null)
        if (!(prevRow.to < value && value < nextRow.to)) {
          return {
            isValid: false,
            message: `Field "To" is invalid: value should lay in range ${prevRow.to} - ${nextRow.to}`,
          };
        }
    }

    if (!prevRow && nextRow) {
      if (!(value < nextRow.to)) {
        return {
          isValid: false,
          message: `Field "To" is invalid: value should be smaller than ${nextRow.to}`,
        };
      }
    }

    if (!nextRow && prevRow) {
      if (value !== '' && value <= from) {
        return {
          isValid: false,
          message: `Field "To" is invalid: value should be greater than ${prevRow.to} or be empty!`,
        };
      }
    }

    return { isValid: true };
  };

  const handleInputChange = (e) => {
    const fieldName = e.target.name;
    const isNumberField = fieldName === 'value' || fieldName === 'to';
    const fieldValue = isNumberField
      ? isNaN(parseFloat(e.target.value))
        ? ''
        : parseFloat(e.target.value)
      : e.target.value;

    setFormState({
      ...formState,
      [fieldName]: fieldValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { isValid, message } = validateToValue(formState.to);
    if (isValid) {
      try {
        await updateTaxBracket(editingTaxBracetId, {
          ...formState,
          to: formState.to ? formState.to : null,
        });
        const nextRow = rows[index + 1];
        if (nextRow) {
          const updatedFrom = formState.to;
          await updateTaxBracket(nextRow.id, { ...nextRow, from: updatedFrom });
          setRows(
            rows.map((row) =>
              row.id === nextRow.id
                ? { ...row, from: updatedFrom }
                : row.id === editingTaxBracetId
                ? { ...formState, id: editingTaxBracetId }
                : row
            )
          );
        } else {
          setRows(
            rows.map((row) =>
              row.id === editingTaxBracetId
                ? { ...formState, id: editingTaxBracetId }
                : row
            )
          );
        }
        successToaster('Tax bracket was successfuly updated!');
        handleClose();
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      errorToaster(message);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={'sm'} fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Edit tax bracket</DialogTitle>
        <DialogContent>
          <TextField
            value={formState.from}
            onChange={handleInputChange}
            disabled
            name="from"
            margin="dense"
            label="From"
            type="number"
            variant="standard"
            fullWidth
          />

          <Box marginBottom={3} />

          <TextField
            required={taxBracketsAmount !== index + 1}
            value={formState.to}
            onChange={handleInputChange}
            name="to"
            margin="dense"
            label="To"
            type="number"
            variant="standard"
            fullWidth
          />

          <Box marginBottom={3} />

          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              name="isFlat"
              value={formState.isFlat}
              label="Type"
              disabled
            >
              <MenuItem value={true}>Flat</MenuItem>
              <MenuItem value={false}>Percent</MenuItem>
            </Select>
          </FormControl>

          <Box marginBottom={3} />

          <TextField
            required
            value={formState.value}
            onChange={handleInputChange}
            name="value"
            margin="dense"
            label="Value"
            type="number"
            inputProps={{
              step: 'any',
            }}
            variant="standard"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type={'submit'} color={'success'} variant={'outlined'}>
            Update
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
