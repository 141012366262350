import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';

export default function CommentDetail({ comment, isOpen, handleClose }) {
  const getCommentStatusFromNumber = (statusNumber) => {
    switch (statusNumber) {
      case 1:
        return 'New';
      case 2:
        return 'Approved';
      case 3:
        return 'In progress';
      case 4:
        return 'Resolved';
      case 5:
        return 'Declined';
      default:
        break;
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={'sm'} fullWidth>
      <Box position={'relative'}>
        <DialogTitle>Comment Detail Info</DialogTitle>
        <DialogContent>
          <Box marginBottom={3}>
            <Typography variant={'body1'} fontWeight={'bold'}>
              Email
            </Typography>
            <Typography variant={'body1'}>{comment.email}</Typography>
          </Box>

          <Box marginBottom={3}>
            <Typography variant={'body1'} fontWeight={'bold'}>
              Text
            </Typography>
            <Typography variant={'body1'}>{comment.text}</Typography>
          </Box>

          <Box marginBottom={3}>
            <Typography variant={'body1'} fontWeight={'bold'}>
              Status
            </Typography>
            <Typography variant={'body1'}>
              {getCommentStatusFromNumber(comment.status)}
            </Typography>
          </Box>
        </DialogContent>
      </Box>

      <IconButton
        onClick={handleClose}
        style={{ position: 'absolute', right: 10, top: 10 }}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}
