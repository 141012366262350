import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import { IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddEmploymentTypeDialog from '../AddEmploymentTypeDialog';
import EditEmploymentTypeDialog from '../EditEmploymentTypeDialog';
import { deleteEmploymentType } from '../../api';
import { successToaster } from '../../../../helpers/notifiers';
import { Link } from 'react-router-dom';
import styles from './EmploymentTypes.module.scss';

function EmploymentTypes({ employmentTypes, loadRegion }) {
  const [rows, setRows] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [changableEmploymentType, setChangableEmploymentType] = useState(null);

  const handleAddModalOpen = () => setIsAddModalOpen(true);
  const handleAddModalClose = () => setIsAddModalOpen(false);

  const handleEditModalOpen = (selectedEmploymentType) => {
    setChangableEmploymentType(selectedEmploymentType);
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => setIsEditModalOpen(false);

  const handleEmploymetTypeDelete = (id) => {
    // eslint-disable-next-line no-restricted-globals
    const allowDelete = confirm('Delete employment type?');
    if (allowDelete) {
      deleteEmploymentType(id).then(() => {
        successToaster('Employment type was successfully deleted!');
        loadRegion();
      });
    }
  };

  useEffect(() => {
    setRows(employmentTypes);
  }, [employmentTypes]);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 450,
      renderCell: (params) => (
        <Link to={`/employmentType/${params.id}`} className={styles.link}>
          {params.value}
        </Link>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Box width={100} display={'flex'} justifyContent={'space-between'}>
          <IconButton onClick={() => handleEditModalOpen(params.row)}>
            <EditIcon color={'info'} />
          </IconButton>
          <IconButton onClick={() => handleEmploymetTypeDelete(params.id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box height={'500px'} marginBottom={20}>
      <Typography variant={'h2'} marginBottom={2} marginTop={5}>
        Country Employment Types
      </Typography>

      <Box marginBottom={2}>
        <IconButton onClick={handleAddModalOpen}>
          <AddCircleIcon />
        </IconButton>
      </Box>

      <DataGrid
        columns={columns}
        rows={rows}
        isRowSelectable={() => null}
        pageSize={100}
      />

      <AddEmploymentTypeDialog
        isOpen={isAddModalOpen}
        loadRegion={loadRegion}
        handleClose={handleAddModalClose}
      />

      <EditEmploymentTypeDialog
        isOpen={isEditModalOpen}
        handleClose={handleEditModalClose}
        employmentType={changableEmploymentType}
        loadRegion={loadRegion}
      />
    </Box>
  );
}

export default React.memo(EmploymentTypes);
