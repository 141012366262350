import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { errorToaster, successToaster } from '../../../helpers/notifiers';
import { createTaxBracket } from '../api';

export default function AddTaxBracketDialog({
  isOpen,
  handleClose,
  from,
  setRows,
  rows,
  taxId,
}) {
  const [formState, setFormState] = useState({
    from,
    to: '',
    value: 0,
    isFlat: false,
  });

  const handleInputChange = (e) => {
    const fieldName = e.target.name;
    const isNumberField = fieldName === 'value' || fieldName === 'to';
    const fieldValue = isNumberField
      ? isNaN(parseFloat(e.target.value))
        ? ''
        : parseFloat(e.target.value)
      : e.target.value;

    setFormState({
      ...formState,
      [fieldName]: fieldValue,
    });
  };

  const validate = () => {
    const from = formState.from;
    const to = formState.to;

    if (!from) {
      return {
        isValid: false,
        message:
          'Field "from" of a new taxBracket can not be empty. Update last tax bracket!',
      };
    }
    if (to !== '' && to <= from) {
      return {
        isValid: false,
        message:
          'Field "to" of last taxbracket should be empty or bigger than "from"!',
      };
    }
    return {
      isValid: true,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { isValid, message } = validate();

      if (isValid) {
        const createdTaxBracket = await createTaxBracket({
          ...formState,
          to: formState.to ? formState.to : null,
          value: formState.value,
          taxId,
        });
        successToaster('Tax bracket was successfuly created!');
        setRows([...rows, { ...formState, id: createdTaxBracket.id }]);
        handleClose();
      } else {
        errorToaster(message);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={'sm'} fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add tax bracket</DialogTitle>
        <DialogContent>
          <TextField
            value={formState.from}
            onChange={handleInputChange}
            disabled
            name="from"
            margin="dense"
            label="From"
            type="number"
            variant="standard"
            fullWidth
          />

          <Box marginBottom={3} />

          <TextField
            value={formState.to}
            onChange={handleInputChange}
            name="to"
            margin="dense"
            label="To"
            type="number"
            variant="standard"
            fullWidth
          />

          <Box marginBottom={3} />

          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              name="isFlat"
              value={formState.isFlat}
              label="Type"
              disabled
            >
              <MenuItem value={true}>Flat</MenuItem>
              <MenuItem value={false}>Percent</MenuItem>
            </Select>
          </FormControl>

          <Box marginBottom={3} />

          <TextField
            required
            value={formState.value}
            onChange={handleInputChange}
            name="value"
            margin="dense"
            label="Value"
            type="number"
            inputProps={{
              step: 'any',
            }}
            variant="standard"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type={'submit'} color={'success'} variant={'outlined'}>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
