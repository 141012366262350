import React, { useEffect } from 'react';
import useMiddlewareReducer from '../hooks/useMiddlewareReducer';
import { middlewares } from '../services/stateMiddlewares';
import Loader from '../shared/Loader';
import { fetchCountries } from './actions/regions';
import {
  START_REQUEST,
  REQUEST_ERROR,
  COUNTRIES_SUCCESS,
  REGION_SUCCESS,
  REGIONS_SUCCESS,
  CLEAR_REGION_INFO,
  REGION_DETAIL_TYPE_TO_COUNTRY,
  REGION_DETAIL_TYPE_TO_REGION,
} from './actionTypes/regions';

export const regionsContext = React.createContext();

const INIT_STATE = {
  countries: [],
  regions: [],
  regionDetailInfo: null,
  regionDetailType: '',
  loading: false,
  error: null,
};

const reducer = (state = INIT_STATE, { payload, type }) => {
  switch (type) {
    case START_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: payload,
        loading: false,
      };
    case REGIONS_SUCCESS:
      return {
        ...state,
        regions: payload,
        loading: false,
      };
    case REGION_SUCCESS: {
      return {
        ...state,
        regionDetailInfo: payload,
        loading: false,
      };
    }
    case CLEAR_REGION_INFO: {
      return {
        ...state,
        regionDetailInfo: null,
      };
    }
    case REGION_DETAIL_TYPE_TO_COUNTRY: {
      return {
        ...state,
        regionDetailType: 'Country',
      };
    }
    case REGION_DETAIL_TYPE_TO_REGION: {
      return {
        ...state,
        regionDetailType: 'Region',
      };
    }
    default:
      return state;
  }
};

export default function RegionsProvider({ children }) {
  const [state, dispatch] = useMiddlewareReducer(
    middlewares,
    reducer,
    INIT_STATE
  );

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  return (
    <regionsContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {state.loading && <Loader />}
      {children}
    </regionsContext.Provider>
  );
}
