import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PublicIcon from '@mui/icons-material/Public';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CommentIcon from '@mui/icons-material/Comment';
import SearchIcon from '@mui/icons-material/Search';

import { Badge, IconButton, Menu, MenuItem } from '@mui/material';
import { regionsContext } from '../../contexts/RegionsProvider';
import { useHistory, useParams } from 'react-router';

const drawerWidth = 240;

export default function Layout({ children }) {
  const {
    state: { countries },
  } = React.useContext(regionsContext);
  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const [commentsAnchorEl, setCommentsAnchorEl] = React.useState(null);

  const isProfileMenuOpen = Boolean(profileAnchorEl);
  const isCommentsMenuOpen = Boolean(commentsAnchorEl);

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleCommentsMenuClose = () => {
    setCommentsAnchorEl(null);
  };

  const handleCommentsMenuOpen = (event) => {
    setCommentsAnchorEl(event.currentTarget);
  };

  const countriesWithNewComments = React.useMemo(() => {
    return countries.filter((country) => country.newComments);
  }, [countries]);

  const history = useHistory();
  const { regionId } = useParams();

  const profileMenuId = 'profile-menu';
  const commentsMenuId = 'comments-menu';

  const renderProfileMenu = (
    <Menu
      anchorEl={profileAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleProfileMenuClose}>Log out</MenuItem>
    </Menu>
  );

  const renderCommentsMenu = (
    <Menu
      anchorEl={commentsAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={commentsMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isCommentsMenuOpen}
      onClose={handleCommentsMenuClose}
    >
      {countriesWithNewComments.map((country) => (
        <MenuItem key={country.id} onClick={handleCommentsMenuClose}>
          <Box
            width={200}
            justifyContent={'space-between'}
            display={'flex'}
            alignItems={'center'}
          >
            <Typography variant={'body2'}>{country.name}</Typography>
            <Badge badgeContent={country.newComments} color="error">
              <CommentIcon />
            </Badge>
          </Box>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Devteam Map Admin
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              size="large"
              aria-label="show new unmoderated comments"
              color="inherit"
              onClick={handleCommentsMenuOpen}
            >
              <Badge
                badgeContent={countriesWithNewComments.length}
                color="error"
              >
                <CommentIcon />
              </Badge>
            </IconButton>

            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <AccountCircle />
                <Typography variant={'body2'}>Dima</Typography>
              </Box>
            </IconButton>
          </Box>
          {renderProfileMenu}
          {renderCommentsMenu}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem onClick={() => history.push('/regions')} button>
              <ListItemIcon>
                <PublicIcon />
              </ListItemIcon>
              <ListItemText primary={'Countries/Regions'} />
            </ListItem>
            {regionId ? (
              <List component="div" disablePadding>
                <ListItem
                  sx={{ pl: 4 }}
                  onClick={() => history.push(`/regions/${regionId}`)}
                  button
                >
                  <ListItemIcon>
                    <SearchIcon />
                  </ListItemIcon>
                  <ListItemText primary={`Region Info`} />
                </ListItem>

                <ListItem
                  sx={{ pl: 4 }}
                  onClick={() => history.push(`/regions/${regionId}/comments`)}
                  button
                >
                  <ListItemIcon>
                    <CommentIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Comments'} />
                </ListItem>
              </List>
            ) : null}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}
