import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/system';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TaxDescription({ isOpen, handleClose, description }) {
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box marginTop={5} width={'100%'} maxWidth={900} marginX={'auto'}>
        <DialogTitle>Tax Description</DialogTitle>
        <DialogContent>
          <Box width={'100%'} maxWidth={900} marginX={'auto'}>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

export default TaxDescription;
