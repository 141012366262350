import React, { memo, useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/system';
import { updateTax } from '../api';
import { successToaster } from '../../../helpers/notifiers';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditTaxDescription({
  isOpen,
  handleClose,
  description,
  id,
  loadData,
}) {
  const [richText, setRichText] = useState('');

  const handleSubmit = async () => {
    try {
      await updateTax(id, { description: richText });
      successToaster('Description of tax was successfully updated!');
      loadData();
      handleClose();
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    setRichText(description);
  }, [description]);

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box marginTop={5} width={'100%'} maxWidth={900} marginX={'auto'}>
        <DialogTitle>Edit Tax Description</DialogTitle>
        <DialogContent>
          <Typography variant={'body1'} marginBottom={1}>
            Description
          </Typography>

          <CKEditor
            editor={ClassicEditor}
            data={richText}
            onChange={(_, editor) => {
              const data = editor.getData();
              setRichText(data);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            type="submit"
            color="success"
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default memo(EditTaxDescription);
