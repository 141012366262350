export const START_REQUEST = 'START_REQUEST';
export const REQUEST_ERROR = 'REQUEST_ERROR';
export const COUNTRIES_SUCCESS = 'COUNTRIES_SUCCESS';
export const REGIONS_SUCCESS = 'REGIONS_SUCCESS';
export const REGION_SUCCESS = 'REGION_SUCCESS';
export const CLEAR_REGION_INFO = 'CLEAR_REGION_INFO';

export const REGION_DETAIL_TYPE_TO_COUNTRY = 'REGION_DETAIL_TYPE_TO_COUNTRY';

export const REGION_DETAIL_TYPE_TO_REGION = 'REGION_DETAIL_TYPE_TO_REGION';
