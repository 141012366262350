import React, { useContext, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { updateCommentStatus } from '../../api';
import { successToaster } from '../../../../helpers/notifiers';
import { regionsContext } from '../../../../contexts/RegionsProvider';
import { fetchCountries } from '../../../../contexts/actions/regions';

export default function EditComment({
  comment,
  isOpen,
  handleClose,
  loadComments,
}) {
  const [status, setStatus] = useState(comment.status);

  const { dispatch } = useContext(regionsContext);

  useEffect(() => {
    setStatus(comment.status);
  }, [comment]);

  const statusVariants = [
    {
      label: 'New',
      value: 1,
    },
    {
      label: 'Approved',
      value: 2,
    },
    {
      label: 'In progress',
      value: 3,
    },
    {
      label: 'Resolved',
      value: 4,
    },
    {
      label: 'Declined',
      value: 5,
    },
  ];

  const handleStatusChange = (e) => setStatus(e.target.value);

  const handleCommentStatusUpdate = () => {
    updateCommentStatus(comment.id, { status })
      .then(() => {
        successToaster('Comment status was successfully updated!');
        loadComments();
        dispatch(fetchCountries());
        handleClose();
      })
      .catch((error) => console.log('error: ', error));
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={'sm'} fullWidth>
      <Box position={'relative'} paddingBottom={2}>
        <DialogTitle>Comment Edit</DialogTitle>
        <DialogContent>
          <Box marginBottom={3}>
            <Typography variant={'body1'} fontWeight={'bold'}>
              Email
            </Typography>
            <Typography variant={'body1'}>{comment?.email}</Typography>
          </Box>

          <Box marginBottom={4}>
            <Typography variant={'body1'} fontWeight={'bold'}>
              Text
            </Typography>
            <Typography variant={'body1'}>{comment?.text}</Typography>
          </Box>

          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={status}
              onChange={handleStatusChange}
              label={'Status'}
            >
              {statusVariants.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Box
            width={190}
            display={'flex'}
            justifyContent={'space-between'}
            marginRight={2}
          >
            <Button
              onClick={handleClose}
              variant={'outlined'}
              color={'primary'}
            >
              Cancel
            </Button>
            <Button
              onClick={handleCommentStatusUpdate}
              variant={'contained'}
              color={'success'}
            >
              Update
            </Button>
          </Box>
        </DialogActions>
      </Box>

      <IconButton
        onClick={handleClose}
        style={{ position: 'absolute', right: 10, top: 10 }}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}
