import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  Button,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link, useParams } from 'react-router-dom';
import TaxTableEditMode from './TaxTableEditMode';
import styles from '../EmploymentTypeDetail.module.scss';
import {
  getOrCreateEmploymentTypeDraft,
  publishEmploymentTypeDraft,
  revertEmploymentTypeDraft,
  updateEmploymentType,
} from '../api';
import Loader from '../../../shared/Loader';
import RegionsTable from '../../../shared/RegionsTable';
import { errorToaster, successToaster } from '../../../helpers/notifiers';
import { fetchRegionDetail } from '../../RegionDetail/api';
import { regionsContext } from '../../../contexts/RegionsProvider';
import { getCountryRegions } from '../../../contexts/actions/regions';
import { normalizeTaxes } from '../helpers';

export default function EmploymentTypeEditMode({ switchMode }) {
  const { dispatch } = useContext(regionsContext);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [taxes, setTaxes] = useState([]);
  const [countryTaxes, setCountryTaxes] = useState([]);
  const [regionTaxes, setRegionTaxes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [employmentTypeRegion, setEmploymentTypeRegion] = useState(null);
  const [employmentTypeDrafId, setEmploymentTypeDrafId] = useState(null);

  const [selectedRegionId, setSelectedRegionId] = useState('');

  const { employmentTypeId } = useParams();

  const loadDraftCopy = useCallback(async () => {
    try {
      setIsLoading(true);
      const fetchedEmploymentType = await getOrCreateEmploymentTypeDraft(
        employmentTypeId
      );
      const { id, name, description, taxes, regionId } = fetchedEmploymentType;

      const normalizedTaxes = normalizeTaxes(taxes);
      const fetchedEmploymentTypeRegion = await fetchRegionDetail(regionId);
      setName(name);
      setDescription(description);
      setTaxes(normalizedTaxes);
      setCountryTaxes(
        normalizedTaxes.filter(
          (tax) => tax.regionId === fetchedEmploymentTypeRegion.id
        )
      );
      if (selectedRegionId) {
        setRegionTaxes(
          normalizedTaxes.filter((tax) => tax.regionId === selectedRegionId)
        );
      }
      setEmploymentTypeRegion(fetchedEmploymentTypeRegion);
      setEmploymentTypeDrafId(id);
      setIsLoading(false);
    } catch (error) {
      console.log('error: ', error);
      errorToaster("Couldn't, load data");
      setIsLoading(false);
    }
  }, [employmentTypeId, selectedRegionId]);

  const handleSubmit = async (name, description) => {
    if (name && description) {
      try {
        const data = { name, description };
        await updateEmploymentType(employmentTypeDrafId, data);
        successToaster('Employment type copy was successfully updated!');
        loadDraftCopy();
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      errorToaster('Both fields should be filled!');
    }
  };

  useEffect(() => {
    loadDraftCopy();
  }, [loadDraftCopy]);

  const handlePublish = async () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmPublish = confirm(
      'Are u sure? This employment type will be published to webapp!'
    );

    if (confirmPublish) {
      try {
        await publishEmploymentTypeDraft(employmentTypeId);
        successToaster('Employment type copy was successfuly published!');
        switchMode();
      } catch (error) {
        console.log('error: ', error);
      }
    }
  };

  const handleRevert = async () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmRevert = confirm(
      'Are u sure? This employment type copy will be removed from db!'
    );

    if (confirmRevert) {
      try {
        await revertEmploymentTypeDraft(employmentTypeId);
        successToaster('Employment type copy was reverted!');
        switchMode();
      } catch (error) {
        console.log('error: ', error);
      }
    }
  };

  useEffect(() => {
    if (employmentTypeRegion) {
      const { countryRegions } = dispatch(
        getCountryRegions(employmentTypeRegion.id)
      );
      setRegions(countryRegions);
    }
  }, [employmentTypeRegion, dispatch]);

  const handleRegionChange = (e) => {
    const regionId = e.target.value;
    const region = regions.find((region) => region.id === regionId);
    setSelectedRegionId(region.id);
    setRegionTaxes(taxes.filter((tax) => tax.regionId === region.id));
  };

  return (
    <Box className={styles.section}>
      {isLoading && <Loader />}

      <Box
        className={styles.switchModeBtn}
        width={250}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <IconButton onClick={switchMode}>
          <ArrowBackIosIcon />
        </IconButton>
        <Button color={'error'} variant={'outlined'} onClick={handleRevert}>
          Revert
        </Button>
        <Button color={'success'} variant={'contained'} onClick={handlePublish}>
          Publish
        </Button>
      </Box>

      <Box className={styles.formSection}>
        <Typography variant={'h1'} marginBottom={5}>
          Employment type edit mode
        </Typography>

        <Typography variant={'body1'} marginBottom={5}>
          All the changes made in draft edit mode will be applied only to the
          copy of an employment type instance. After u click a publish button,
          copy of the instance will be destoyed, while the original instance
          will be updated with data from deleted copy.
        </Typography>

        <Typography variant={'h2'} marginBottom={4}>
          Country:{' '}
          <Link
            className={styles.link}
            to={`/regions/${employmentTypeRegion?.id}`}
          >
            {employmentTypeRegion?.name}
          </Link>
        </Typography>

        <TextField
          required
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          value={name}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setName(e.target.value)}
        />

        <Box marginBottom={5} />

        <CKEditor
          editor={ClassicEditor}
          data={description}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log('Editor is ready to use!', editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setDescription(data);
            console.log({ event, editor, data });
          }}
        />

        <Button
          color="success"
          variant={'contained'}
          className={styles.btn}
          onClick={() => handleSubmit(name, description)}
        >
          Save
        </Button>
      </Box>

      <TaxTableEditMode
        taxes={countryTaxes}
        loadData={loadDraftCopy}
        employmentTypeId={employmentTypeDrafId}
        regionId={employmentTypeRegion?.id}
        type="Country"
      />

      <Box marginTop={25} marginBottom={3}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Choose Region For Taxes
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedRegionId}
            label="Choose Region For Taxes"
            onChange={handleRegionChange}
          >
            {regions.map((region) => (
              <MenuItem key={region.id} value={region.id}>
                {region.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {selectedRegionId && (
        <TaxTableEditMode
          taxes={regionTaxes}
          loadData={loadDraftCopy}
          employmentTypeId={employmentTypeDrafId}
          regionId={selectedRegionId}
          type="Region"
        />
      )}
      <Box marginBottom={10} />
      <RegionsTable entites={regions} tableType="regions" />
    </Box>
  );
}
