import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Typography, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { Box } from '@mui/system';
import TaxBracketTableReadMode from './TaxBracketTableReadMode';
import TaxDescription from './TaxDescription';

export default function TaxTableReadMode({ taxes, type }) {
  const [isTaxBracketTableOpen, setIsTaxBracketTableOpen] = useState(false);
  const [selectedTaxBrackets, setSelectedTaxBrackets] = useState([]);
  const [rows, setRows] = useState([]);

  const [isTaxDescriptionOpen, setIsTaxDescriptionOpen] = useState(false);
  const [selectedTaxDescription, setSelectedTaxDescription] = useState('');

  const handleTaxBracketsTableOpen = (taxBrackets) => {
    setIsTaxBracketTableOpen(true);
    setSelectedTaxBrackets(taxBrackets);
  };

  const handleTaxBracketsTableClose = () => {
    setIsTaxBracketTableOpen(false);
    setSelectedTaxBrackets([]);
  };

  const handleTaxDescriptionClose = () => setIsTaxDescriptionOpen(false);

  useEffect(() => {
    if (taxes) {
      const sortedTaxes = taxes.sort((a, b) => a.order - b.order);
      setRows(sortedTaxes);
    }
  }, [taxes]);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 220,
      required: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setSelectedTaxDescription(params.value);
            setIsTaxDescriptionOpen(true);
          }}
        >
          <ManageSearchIcon color={'info'} />
        </IconButton>
      ),
    },
    {
      field: 'isPersonal',
      headerName: 'Level*',
      width: 180,
      renderCell: (params) =>
        params.value ? (
          <Typography variant={'body1'}>Personal</Typography>
        ) : (
          <Typography variant={'body1'}>Company</Typography>
        ),
    },
    {
      field: 'deduction',
      headerName: 'Deduction',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
    },
    {
      field: 'topLimit',
      headerName: 'Top limit',
      width: 90,
      align: 'center',
      headerAlign: 'center',
      type: 'number',
    },
    {
      field: 'order',
      headerName: 'Order',
      width: 70,
      align: 'center',
      headerAlign: 'center',
      required: true,
      type: 'number',
    },
    {
      field: 'brackets',
      headerName: 'Has brackets',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) =>
        params.row.brackets.length ? (
          <CheckIcon color={'success'} />
        ) : (
          <CloseIcon color={'error'} />
        ),
    },
    {
      field: 'isFlat',
      headerName: 'Type',
      width: 70,
      renderCell: (params) => (
        <Typography variant={'body1'}>
          {params.row.brackets[0]?.isFlat === true
            ? 'Flat'
            : params.row.brackets[0]?.isFlat === false
            ? 'Percent'
            : ''}
        </Typography>
      ),
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 70,
      align: 'center',
      headerAlign: 'center',
      required: true,
      type: 'number',
      renderCell: (params) => (
        <Typography variant={'body1'}>
          {params.row.brackets[0]?.value ?? ''}
        </Typography>
      ),
    },
    {
      field: 'bracketsLink',
      headerName: 'Brackets link',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      required: true,
      type: 'number',
      renderCell: (params) => (
        <Typography variant={'body1'}>
          <Button
            color={'info'}
            variant={'outlined'}
            disabled={!params.row.brackets.length}
            style={{ fontSize: 10 }}
            onClick={() => handleTaxBracketsTableOpen(params.row.brackets)}
          >
            Tax <br /> brackets
          </Button>
        </Typography>
      ),
    },
  ];

  return (
    <Box width={'100%'} height={'70vh'} marginTop={5} marginBottom={20}>
      <Typography variant={'h2'} marginBottom={2}>
        {type} Taxes Table
      </Typography>
      <Box height={'70vh'}>
        <DataGrid
          columns={columns}
          rows={rows}
          isRowSelectable={() => null}
          pageSize={100}
        />
      </Box>

      <TaxDescription
        description={selectedTaxDescription}
        isOpen={isTaxDescriptionOpen}
        handleClose={handleTaxDescriptionClose}
      />

      <TaxBracketTableReadMode
        isOpen={isTaxBracketTableOpen}
        handleClose={handleTaxBracketsTableClose}
        taxBrackets={selectedTaxBrackets}
      />
    </Box>
  );
}
