import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import styles from '../EmploymentTypeDetail.module.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TaxBracketTableReadMode({
  taxBrackets,
  isOpen,
  handleClose,
}) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(taxBrackets);
  }, [taxBrackets]);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'from',
      headerName: 'From',
      width: 140,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'to',
      headerName: 'To',
      width: 140,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) =>
        params.value ? (
          <Typography variant={'body2'}>{params.value}</Typography>
        ) : (
          <Typography variant={'body2'}>and up</Typography>
        ),
    },
    {
      field: 'isFlat',
      headerName: 'Type',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      visible: false,
      renderCell: (params) =>
        params.value ? (
          <Typography variant={'body2'}>Flat</Typography>
        ) : (
          <Typography variant={'body2'}>Percent</Typography>
        ),
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 70,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box height={'70vh'} className={styles.section}>
        <Typography variant={'h1'} marginY={2}>
          Tax brackets
        </Typography>

        <DataGrid
          columns={columns}
          rows={rows}
          isRowSelectable={() => null}
          pageSize={100}
        />
      </Box>
    </Dialog>
  );
}
