import axiosClient from '../../services/configuredAxios';

export const fetchRegionDetail = async (id) => {
  try {
    const { data } = await axiosClient.get(`/region/${id}`);
    return data.result;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const createEmploymentType = async (data) => {
  try {
    await axiosClient.post('/EmploymentType/', data);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateEmploymentType = async (id, data) => {
  try {
    await axiosClient.put(`/EmploymentType/${id}`, data);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteEmploymentType = async (id) => {
  try {
    await axiosClient.delete(`/EmploymentType/${id}`);
  } catch (error) {
    throw new Error(error.message);
  }
};
