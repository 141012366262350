import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
  // baseURL: 'http://localhost:8000',
});

axiosClient.defaults.headers.common[
  'Authorization'
] = `Bearer ${process.env.REACT_APP_BACKEND_API_TOKEN}`;

export default axiosClient;
