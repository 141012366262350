import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/system/Box';
import styles from './AddEmploymentTypeDialog.module.scss';
import { TextField } from '@mui/material';
import { createEmploymentType } from '../../api';
import { errorToaster, successToaster } from '../../../../helpers/notifiers';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddEmploymentTypeDialog({
  isOpen,
  handleClose,
  loadRegion,
}) {
  const [formState, setFormState] = useState({
    name: '',
    description: '',
  });

  const { regionId } = useParams();
  const mounted = useRef();

  const resetForm = () =>
    setFormState({
      name: '',
      description: '',
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, description } = formState;
    if (name && description) {
      const data = { name, description, regionId: +regionId, parentId: null };
      console.log('data: ', data);
      createEmploymentType(data)
        .then(() => {
          successToaster('New employment type was successfully added!');
          loadRegion();
          handleClose();
        })
        .catch((error) => {
          console.log('error: ', error);
        });
    } else {
      errorToaster('Both fields should be filled!');
    }
  };

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      resetForm();
    }
  }, [isOpen]);

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box className={styles.section}>
        <Typography variant={'h1'} marginBottom={5}>
          Add employment type
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label="Name"
            name="name"
            variant="outlined"
            value={formState.name}
            onChange={(e) => {
              setFormState({
                ...formState,
                name: e.target.value,
              });
            }}
          />

          <Typography variant={'body1'} marginTop={5} marginBottom={2}>
            Description
          </Typography>

          <CKEditor
            editor={ClassicEditor}
            data={formState.description}
            onChange={(_, editor) => {
              const data = editor.getData();
              setFormState({
                ...formState,
                description: data,
              });
            }}
          />

          <Button
            type="submit"
            className={styles.btn}
            variant={'contained'}
            color="success"
          >
            Submit
          </Button>
        </form>
      </Box>
    </Dialog>
  );
}
