import { useMemo, useRef, useState } from 'react';

export default function useMiddlewareReducer(
  middlewares,
  reducer,
  initState,
  initializer = (s) => s
) {
  const [state, setState] = useState(initializer(initState));
  const stateRef = useRef(state); // stores most recent state
  const dispatch = useMemo(
    () =>
      enhanceDispatch({
        getState: () => stateRef.current, // access most recent state
        stateDispatch: (action) => {
          stateRef.current = reducer(stateRef.current, action); // makes getState() possible
          setState(stateRef.current); // trigger re-render
          return action;
        },
      })(...middlewares),
    [middlewares, reducer]
  );

  function enhanceDispatch({ getState, stateDispatch }) {
    return (...middlewares) => {
      let dispatch;
      const middlewareAPI = {
        getState,
        dispatch: (action) => dispatch(action),
      };
      dispatch = middlewares
        .map((m) => m(middlewareAPI))
        .reduceRight((next, mw) => mw(next), stateDispatch);
      return dispatch;
    };
  }

  return [state, dispatch];
}
