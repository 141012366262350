import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Box } from '@mui/system';
import { Link, useParams } from 'react-router-dom';
import styles from '../EmploymentTypeDetail.module.scss';
import TaxTableReadMode from './TaxTableReadMode';
import { fetchEmploymentType } from '../api';
import { fetchRegionDetail } from '../../RegionDetail/api';
import Loader from '../../../shared/Loader';
import { regionsContext } from '../../../contexts/RegionsProvider';
import { getCountryRegions } from '../../../contexts/actions/regions';
import { normalizeTaxes } from '../helpers';

export default function EmploymentTypeReadMode({ switchMode }) {
  const { dispatch } = useContext(regionsContext);
  const [employmentTypeData, setEmploymentTypeData] = useState(null);
  const [employmentTypeRegion, setEmploymentTypeRegion] = useState(null);
  const [countryTaxes, setCountryTaxes] = useState([]);
  const [regionTaxes, setRegionTaxes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [selectedRegionId, setSelectedRegionId] = useState('');

  const { employmentTypeId } = useParams();

  useEffect(() => {
    const loadData = async () => {
      try {
        const fetchedEmploymentType = await fetchEmploymentType(
          employmentTypeId
        );
        const normolizedTaxes = normalizeTaxes(fetchedEmploymentType.taxes);
        setEmploymentTypeData({
          ...fetchedEmploymentType,
          taxes: normolizedTaxes,
        });
        const fetchedEmploymentTypeRegion = await fetchRegionDetail(
          fetchedEmploymentType.regionId
        );
        setEmploymentTypeRegion(fetchedEmploymentTypeRegion);
        setCountryTaxes(
          normolizedTaxes.filter(
            (tax) => tax.regionId === fetchedEmploymentTypeRegion.id
          )
        );
      } catch (error) {
        console.log('error: ', error);
      }
    };
    loadData();
  }, [employmentTypeId]);

  useEffect(() => {
    if (employmentTypeRegion) {
      const { countryRegions } = dispatch(
        getCountryRegions(employmentTypeRegion.id)
      );
      setRegions(countryRegions);
    }
  }, [employmentTypeRegion, dispatch]);

  const handleRegionChange = (e) => {
    const regionId = e.target.value;
    const region = regions.find((region) => region.id === regionId);
    setSelectedRegionId(region.id);
    setRegionTaxes(
      employmentTypeData.taxes.filter((tax) => tax.regionId === region.id)
    );
  };

  const { name, description } = employmentTypeData ?? {};

  if (!employmentTypeData) {
    return <Loader />;
  }

  return (
    <Box className={styles.section}>
      <Button
        color={'info'}
        variant={'contained'}
        className={styles.switchModeBtn}
        onClick={switchMode}
      >
        Edit
      </Button>

      <Box className={styles.formSection}>
        <Typography variant={'h1'} marginBottom={5}>
          Employment type Info
        </Typography>

        <Typography variant={'h2'} marginBottom={4}>
          Country:{' '}
          <Link
            className={styles.link}
            to={`/regions/${employmentTypeRegion?.id}`}
          >
            {employmentTypeRegion?.name}
          </Link>
        </Typography>

        <Typography variant={'h3'} marginBottom={2} fontWeight={600}>
          Name:
        </Typography>

        <Typography variant={'h3'} marginBottom={5}>
          {name}
        </Typography>

        <Typography variant={'h3'} fontWeight={600}>
          Description:
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Box>

      <TaxTableReadMode taxes={countryTaxes} type={'Country'} />

      <Box marginTop={25} marginBottom={3}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Choose Region For Taxes
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedRegionId}
            label="Choose Region For Taxes"
            onChange={handleRegionChange}
          >
            {regions.map((region) => (
              <MenuItem key={region.id} value={region.id}>
                {region.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <TaxTableReadMode taxes={regionTaxes} type={'Region'} />
    </Box>
  );
}
