import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Link } from 'react-router-dom';
import { updateEmploymentType } from '../../api';
import { successToaster } from '../../../../helpers/notifiers';

export default function EditEmploymentTypeDialog({
  isOpen,
  handleClose,
  employmentType,
  loadRegion,
}) {
  const [name, setName] = useState('');

  const handleChange = (e) => setName(e.target.value);
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { name };
    updateEmploymentType(employmentType?.id, data)
      .then(() => {
        successToaster('Employment type was successfully updated!');
        loadRegion();
        handleClose();
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };

  const setToInitialState = () => setName(employmentType?.name);

  const handleCancel = () => {
    setToInitialState();
    handleClose();
  };

  useEffect(() => {
    setName(employmentType?.name);
  }, [employmentType]);

  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Edit</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can update the employment type name in the current modal form,
            or go to a{' '}
            <Link
              style={{ textDecoration: 'none' }}
              to={`/employmentType/${employmentType?.id}`}
            >
              employment type detail page
            </Link>{' '}
            for a complex update.
          </DialogContentText>
          <TextField
            required
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
