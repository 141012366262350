import React from 'react';
import RegionsProvider from './contexts/RegionsProvider';
import Routes from './routes';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

toast.configure();

function App() {
  return (
    <RegionsProvider>
      <Routes />
    </RegionsProvider>
  );
}

export default App;
